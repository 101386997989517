import * as React from "react"
import { Link } from "gatsby"

// Styles
import * as styles from "./ArtistMenu.module.css"

type ArtistMenuProps = {
  slug: string
  hasCovers?: boolean
}

const ArtistMenu: React.FC<ArtistMenuProps> = ({ slug, hasCovers = true }) => {
  if (hasCovers)
    return (
      <>
        <nav className={styles.artist__nav}>
          <Link
            partiallyActive
            activeClassName={styles.selected}
            className={styles.artist__nav_option}
            to={`/artists/${slug}/songs`}
          >
            Originales
          </Link>
          <Link
            partiallyActive
            activeClassName={styles.selected}
            className={styles.artist__nav_option}
            to={`/artists/${slug}/covers`}
          >
            Covers
          </Link>
        </nav>
      </>
    )
  return (
    <>
      <nav className={styles.artist__nav}>
        <Link
          partiallyActive
          activeClassName={styles.selected}
          className={styles.artist__nav_option}
          to={`/artists/${slug}/songs`}
        >
          Originales
        </Link>
        <div className={styles.artist__nav_option_disabled}>Covers</div>
      </nav>
    </>
  )
}

export default ArtistMenu
