import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Components
import Sidebar from "components/Layout/Sidebar"
// Models
import { IArtist } from "models/Artist"
import { Link } from "gatsby"

type ArtistSidebarProps = {
  artist: IArtist
  songs: number
  covers: number
}

const ArtistSidebar: React.FC<ArtistSidebarProps> = ({ artist, songs, covers }) => {
  const { spotify, twitter, facebook, instagram, website, youtube, line } = artist
  const artistAvatar = getImage(artist.avatar.childImageSharp.gatsbyImageData)
  return (
    <Sidebar>
      <h2 className="title">Artista</h2>
      <div className={"sidebar_featured"}>
        {artistAvatar && <GatsbyImage className={"sidebar_featured__avatar"} image={artistAvatar} alt={artist.name} />}
        <div className={"sidebar_featured__info"}>
          <h3 className={"sidebar_featured__name"}>{artist.name}</h3>
          <div className={"sidebar_featured__stats"}>
            {!!songs && <Link to={`/artists/${artist.slug}/songs`}>Canciones: {songs}</Link>}
            {!!covers && <Link to={`/artists/${artist.slug}/covers`}>Covers: {covers}</Link>}
          </div>
        </div>
        <div className={"sidebar_featured__social"}>
          {youtube && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              className={"sidebar_featured__social_link"}
              href={youtube}
              aria-label="Youtube"
            >
              <FontAwesomeIcon icon={["fab", "youtube"]} />
            </a>
          )}
          {spotify && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              className={"sidebar_featured__social_link"}
              href={spotify}
              aria-label="Spotify"
            >
              <FontAwesomeIcon icon={["fab", "spotify"]} />
            </a>
          )}
          {twitter && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              className={"sidebar_featured__social_link"}
              href={`https://twitter.com/${twitter}`}
              aria-label="Twitter"
            >
              <FontAwesomeIcon icon={["fab", "twitter"]} />
            </a>
          )}
          {instagram && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              className={"sidebar_featured__social_link"}
              href={`https://instagram.com/${instagram}`}
              aria-label="Instagram"
            >
              <FontAwesomeIcon icon={["fab", "instagram"]} />
            </a>
          )}
          {facebook && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              className={"sidebar_featured__social_link"}
              href={`https://facebook.com/${facebook}`}
              aria-label="Facebook"
            >
              <FontAwesomeIcon icon={["fab", "facebook"]} />
            </a>
          )}
          {line && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              className={"sidebar_featured__social_link"}
              href={`${line}`}
              aria-label="Line"
            >
              <FontAwesomeIcon icon={["fab", "line"]} />
            </a>
          )}
          {website && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              className={"sidebar_featured__social_link"}
              href={website}
              aria-label="Página web"
            >
              <FontAwesomeIcon icon={["fas", "globe"]} />
            </a>
          )}
        </div>
      </div>
      <p className={"sidebar_card_alt"}>{artist.description}</p>
    </Sidebar>
  )
}

export default ArtistSidebar
