import * as React from "react"
import { graphql, PageProps } from "gatsby"

// Components
import Layout from "components/Layout"
import Main from "components/Layout/Main"
import SongItem from "components/Sections/Songs/SongItem"
import ArtistMenu from "components/Sections/Artist/ArtistMenu"
import ArtistSidebar from "components/Sections/Artist/ArtistSidebar"
import Pagination from "components/UIElements/Pagination"
// Models
import { ISong } from "models/Song"
import { IArtist } from "models/Artist"
import { IOptionalMetaProps } from "models/Seo"

type DataProps = {
  artist: IArtist
  songs: {
    totalCount: number
  }
  covers: {
    totalCount: number
    nodes: ISong[]
  }
}

type PageContextProps = {
  currentPage: number
  totalPages: number
}

const BlogIndex: React.FC<PageProps<DataProps, PageContextProps>> = ({ data, pageContext }) => {
  const { currentPage, totalPages } = pageContext
  const artist = data.artist
  const totalSongs = data.songs.totalCount
  const covers = data.covers.nodes
  const totalCovers = data.covers.totalCount

  const seo: IOptionalMetaProps = {
    title: `Covers de ${artist.name}`,
    author: { name: artist.name, twitter: artist.twitter },
    description: `Covers publicados de ${artist.name}${
      artist.description && `. Información sobre ${artist.name}: ${artist.description}`
    }.`,
    image: artist.avatar.url,
  }

  return (
    <Layout seo={seo}>
      <Main sidebar>
        <h2 className="title">Covers de {artist.name}</h2>
        <ArtistMenu slug={artist.slug} />
        <div>
          {covers.map(song => (
            <SongItem key={song.fields.slug} song={song} />
          ))}
        </div>
        <Pagination
          url={`/artists/${artist.slug}/covers`}
          title="Covers"
          currentPage={currentPage}
          totalPages={totalPages}
        />
      </Main>
      <ArtistSidebar artist={artist} songs={totalSongs} covers={totalCovers} />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query ArtistCoversPage($slug: [String]!, $limit: Int!, $skip: Int!) {
    artist: artist(slug: { in: $slug }) {
      name
      slug
      description
      spotify
      youtube
      twitter
      facebook
      instagram
      line
      website
      avatar {
        url
        childImageSharp {
          gatsbyImageData(width: 100, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
    songs: allMdx(filter: { frontmatter: { authors: { in: $slug } }, fields: { collection: { eq: "songs" } } }) {
      totalCount
    }

    covers: allMdx(
      filter: { frontmatter: { artists: { in: $slug } }, fields: { collection: { eq: "songs" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      nodes {
        fields {
          slug
          author_name
        }
        frontmatter {
          title
          authors
          date(formatString: "DD [de] MMMM, YYYY", locale: "es")
          thumbnail {
            childImageSharp {
              gatsbyImageData(width: 200, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  }
`
